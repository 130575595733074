<template>
  <div class="templates-table">
    <div>

    </div>
    <CDataTable
      id="rewardsTable"
      class="zq--table"
      :items="fileObjects"
      :fields="customFields"
      :items-per-page="itemsPerPage"
      :sorter="{ external: true }"
      :responsive="true"
      :border="isDefaultTheme"
      :hover="isDefaultTheme"
      :striped="isDefaultTheme"
      :loading="loading"
      @pagination-change="itemsPerPageSelect"
      @page-change="paginationChange"
      @update:sorter-value="handleSortBy"
    >
      <template #id="{ item }">
        <td class="nth-id">
          <div class="link" @click="getTemplate(item)">
            {{ item.id }}
          </div>
        </td>
      </template>
      <template #actions="{ item }">
        <td>
          <div class="link" @click="getTemplate(item)">
            Use
          </div>
        </td>
      </template>
    </CDataTable>
    <TableFooter
      :page="page"
      @updatePagenation="paginationChange"
      :pages="pages"
      :total="resultCount"
      :itemsPerPage="itemsPerPage"
      @updateItemPerPage="itemsPerPageSelect"
    />
  </div>
</template>

<script>
import TableFooter from '@/components/table/Footer';
import { pageConfig } from '@/config';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TemplatesTable',
  components: {
    TableFooter,
  },
  props: {
    context: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      page: pageConfig.page,
      itemsPerPage: pageConfig.itemsPerPage,
      query: {
        idArray: [],
        queryRequest: {},
      },
      fileObjects: [],
      customFields: [
        'id',
        'name',
        'created',
        'parentFolderPath',
        'mimeType',
        'size',
        'actions',
      ],
    }
  },
  created() {
    this.initialize()
  },
  computed: {
    ...mapGetters('files', ['pages', 'resultCount', 'loading']),
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default'
    },
  },
  methods: {
    ...mapActions('files', ['handleGetFileObjectsByQuery', 'handleDownloadFileObjects']),
    ...mapActions('fileRepositories', ['handleGetFileRepositoriesByQuery']),
    async initialize() {
      const repository = await this.handleGetFileRepositoriesByQuery({
        queryRequest: {
          must: [{
            queryField: 'name',
            queryValues: ['system-resources'],
          }]
        }
      });

      const folderName = this.getTemplateFolderName();

      this.sortBy = [{
        queryField: 'name',
        order: 'Asc',
      }];

      this.query.queryRequest = {
        must: [
          {
            queryField: 'repositoryId',
            queryValues: [repository[0].id],
          },
          {
            queryField: 'parentFolderPath',
            queryValues: [folderName],
          }
        ],
        sortBy: this.sortBy,
        limit: this.itemsPerPage,
        skip: 0
      };
      this.fileObjects = await this.handleGetFileObjectsByQuery(this.query)
    },
    async getTemplate(item) {
      let template = await this.handleDownloadFileObjects({path: item.path});

      if (typeof template === 'object') {
        this.$emit('getFromTemplate', template)
      } else {
        console.error('Invalid file type')
      }
    },
    getTemplateFolderName() {
      let folderName = '';

      switch (this.context) {
        case 'competition':
          folderName = '/rules/competitions';
          break;
        case 'contest':
          folderName = '/rules/contests';
          break;
        case 'achievement':
          folderName = '/rules/achievements';
          break;
      }

      return folderName;
    },
    async paginationChange(val) {
      this.page = val;

      this.query.queryRequest.skip = (this.page - 1) * this.itemsPerPage

      this.fileObjects = await this.handleGetFileObjectsByQuery(this.query)
    },
    itemsPerPageSelect(val) {
      this.itemsPerPage = val;
      this.page = 1;
      this.handleGetFileObjectsByQuery(this.query);
    },
    async handleSortBy(e) {
      if (this.skipSort && this.skipSort.includes(e.column)) {
        return;
      }

      this.query.queryRequest.sortBy = [{
        queryField: e.column,
        order: e.asc ? 'Asc' : 'Desc',
      }];

      this.page = 1;

      this.fileObjects = await this.handleGetFileObjectsByQuery(this.query)
    },
  },
}
</script>

<style scoped>

</style>